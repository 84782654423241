require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "../stylesheets/application.scss"
import flatpickr from "flatpickr"

import Alpine from 'alpinejs'
import mask from '@alpinejs/mask'
window.Alpine = Alpine
Alpine.start()
Alpine.plugin(mask)
// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

if(window.location.pathname.split('/')[1] == 'results') {
  window.onpopstate = function(e){
      location.reload();
  }

  document.addEventListener('DOMContentLoaded', function () {

    document.addEventListener("click", function(event) {

      if(event.target.matches('.print-single-test-results') || event.target.matches('.print-single-test-results span')) {
        event.preventDefault();
        if(document.querySelector('.results-ready')) {
          document.querySelector('.results-ready').classList.remove('print-all');
        }
        window.print();
      }
      else if(event.target.matches('.print-full-test-results') || event.target.matches('.print-full-test-results span')) {
        event.preventDefault();
        if(document.querySelector('.results-ready')) {
          document.querySelector('.results-ready').classList.add('print-all');
        }
        window.print();
      }
    });

  }, false);

  let results_links = document.querySelectorAll('.event-list li a, .step-list li a');

  for (i = 0; i < results_links.length; ++i) {
    results_links[i].addEventListener("click", function(event) {
      this.nextElementSibling.classList.remove('ready');
      this.nextElementSibling.classList.remove('pending');
      this.nextElementSibling.classList.add('loading');
    });
  }

}

if(window.location.pathname.split('/')[1] == 'dashboard') {
  let dashboard_results = document.querySelectorAll(".content__results li a");
  console.log('dashboard_results: ' + dashboard_results.length);
  let j;
  for (j = 0; j < dashboard_results.length; ++j) {
    dashboard_results[j].addEventListener("click", function(event) {
      console.log('clicked');
      this.nextElementSibling.children[0].classList.remove('ready');
      this.nextElementSibling.children[0].classList.add('loading');
    });
  }
}
